<template>
  <div class="content-1 up-1" v-scrollanimate>
    <router-link class="image-1" to="/solution#sri">
      <picture>
        <source srcset="~@/assets/sri_preview.webp" type="image/webp">
        <source srcset="~@/assets/sri_preview.jpg" type="image/jpeg">
        <img src="~@/assets/sri_preview.jpg" alt="SRI cover" />
      </picture>
    </router-link>
    <h4 class="h3 t-1">SRI — The most efficient complete system highway interchange</h4>
    <div class="p-2">Roadhub's Spiral Ring Interchange (SRI) reinvents the highway interchange. The standard types of highway interchanges in use worldwide either take up too much land, are too expensive to build, or are not very efficient. SRI introduces a new type of geometry: a design solution that requires less land, is cheaper to build, safer, and allows better car flow through it.<br><br>As a result, efficient highway traffic is now achievable in areas where land was previously considered either too small or too expensive for the building of a complete system interchange.</div>
    <router-link class="link" to="/solution#sri">
      <button class="btn">Read more</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HomeContent1'
}
</script>

<style lang="scss" scoped>
.content-1 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  .image-1 {
    grid-column: 1 / 15;
    grid-row: 1 / 3;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 6;
    grid-row: 3 / 4;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      margin-top: 64px;
    }
  }
  .p-2 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 4 / 5;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .link {
    grid-column: 8 / 13;
    grid-row: 4 / 5;
    margin-top: 64px;
    @include tablet {
      grid-column: 2 / 7;
      grid-row: 5 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    button {
      color: $c-a;
      background: none;
      border: 1px solid $c-a;
      border-radius: 28px;
      width: 276px;
      height: 56px;
      &:hover {
        color: $c-w;
        background: $c-e;
        border: 1px solid $c-e;
      }
      @include laptop {
        width: 184px;
        height: 48px;
      }
      @include tablet {
        width: 168px;
        height: 48px;
      }
    }
  }

}

</style>
