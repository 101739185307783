<template>
  <div class="cta-1 up-1" v-scrollanimate>
    <h6 class="cta-contact h4">The great challenges of today can’t be solved using yesterday’s thinking.<br><router-link class="link" to="/challenge"><span class="eff-base-r eff-2 eff-w">Learn more.</span></router-link></h6>
  </div>
</template>

<script>
export default {
  name: 'HomeCallToAction'

}
</script>

<style lang="scss" scoped>
.cta-1 {
  @include grid;
  height: 256px;
  color: $c-w;
  background-color: $c-e;
  align-items: center;
  @include laptop {
    height: 192px;
  }
  .cta-contact {
    grid-column: 2 / 14;
    grid-row: 1;
    max-width: 576px;
    text-align: center;
    justify-self: center;
    @include laptop {
      max-width: 512px;
    }
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
}

</style>
