import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/challenge',
    name: 'Challenge',
    // route level code-splitting
    // this generates a separate chunk (challenge.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "challenge" */ '../views/Challenge.vue')
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import(/* webpackChunkName: "solution" */ '../views/Solution.vue')
  },
  {
    path: '/journey',
    name: 'Journey',
    component: () => import(/* webpackChunkName: "journey" */ '../views/Journey.vue')
  },
  {
    path: '/business',
    name: 'Business',
    component: () => import(/* webpackChunkName: "business" */ '../views/Business.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  // 404 redirect
  {
    path: '*',
    name: 'Missing',
    component: () => import(/* webpackChunkName: "missing" */ '../views/Missing.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ''))
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }
      }, 750)
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
  routes
})

export default router
