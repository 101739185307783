<template>
  <div id="app" class="layout">
    <NavHeaderAlt class="z-1"/>
    <TransitionPage>
      <router-view/>
    </TransitionPage>
  </div>
</template>

<script>
import NavHeaderAlt from './components/NavHeaderAlt'
import TransitionPage from './components/TransitionPage'
export default {
  name: 'App',
  components: {
    NavHeaderAlt,
    TransitionPage
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

.page {
  width: 100%;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.z-1 {
  z-index: 99;
}
/* body {
  overflow-x: hidden;
  width: 100vw;
} */
body.overlay-open {
  overflow: hidden;
}
@import "@/scss/_fonts.scss";
@import "@/scss/_globals.scss";
</style>
