<template>
  <div class="home page">
    <HomeVideo/>
    <HomeMission/>
    <HomeContent1/>
    <HomeContent2/>
    <HomeCallToAction/>
    <NavFooter/>
  </div>
</template>

<script>
import HomeVideo from '../components/HomeVideo'
import HomeMission from '../components/HomeMission'
import HomeContent1 from '../components/HomeContent1'
import HomeContent2 from '../components/HomeContent2'
import HomeCallToAction from '../components/HomeCallToAction'
import NavFooter from '../components/NavFooter'

export default {
  name: 'Home',
  components: {
    HomeVideo,
    HomeMission,
    HomeContent1,
    HomeContent2,
    HomeCallToAction,
    NavFooter
  }
}
</script>

<style lang="scss" scoped>

</style>
