<template>
  <div class="footer">
    <div class="nav-footer small">
      <div class="logo-f">
        <router-link class="link" to="/">
          <picture class="logo" @click="toTop()">
            <source srcset="~@/assets/logo.svg" type="image/svg">
            <img src="~@/assets/logo.svg" alt="SRI logo" />
          </picture>
        </router-link>
      </div>
<!--       <div class="scroll-fix" :class="{'home': homePage}">
        <div class="click" @click="goTop"></div>
      </div> -->
      <div class="links">
        <ul class="f-list-1">
          <li>Reach out today</li>
          <li>
            <a class="link eff-base-r eff-1 eff-b" :href="prefix+decoded">Email</a>
          </li>
        </ul>
        <ul class="f-list-2">
          <li>
            <router-link class="link eff-base-r eff-1 eff-b" to="/terms">Terms</router-link>
          </li>
          <li>
            <router-link class="link eff-base-r eff-1 eff-b" to="/privacy">Privacy Policy</router-link>
          </li>
        </ul>
        <ul class="f-list-3">
          <li>
            <a class="link eff-base-r eff-1 eff-b" href="https://www.linkedin.com/posts/yaron-toren-b42391_highways-infrastructureprojects-civilconstruction-activity-6654777609777356800-r8Pn/" target="_blank" rel="noopener">LinkedIn</a>
          </li>
          <li>Made by <a class="link eff-base-r eff-1 eff-b" href="https://triuna.si/" target="_blank" rel="noopener">Triuna</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavFooter',
  data () {
    return {
      homePage: false,
      decoded: atob('aGVsbG9Acm9hZGh1Yi5kZXNpZ24='),
      prefix: atob('bWFpbHRvOg==')
    }
  },
  methods: {
    toTop () {
      if (this.$route.path === '/') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
}
.nav-footer {
  @include grid;
  height: 256px;
  align-items: center;
  @include tablet {
    height: 192px;
  }
  @include phone {
    height: 512px;
  }
  .logo-f {
    grid-column: 2 / 5;
    grid-row: 1 / 2;
    opacity: 1;
    display: flex;
    align-items: center;
    z-index: 5;
    .logo {
      display: block;
      height: 46px;
      width: 137px;
      img {
        width: 137px;
      }
    }
  }
  .scroll-fix {
    display: none;
    grid-column: 2 / 5;
    grid-row: 1 / 2;
    display: flex;
    align-items: center;
    height: 68px;
    width: 126px;
    z-index: 10;
    &.home {
      display: block;
    }
    .click {
      width: 100%;
      height: 100%;
    }
  }
  .links {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include laptop {
      grid-column: 8 / 14;
    }
    @include tablet {
      grid-column: 5 / 10;
    }
    @include phone {
      grid-column: 2 / 5;
      grid-row: 2 / 3;
      flex-direction: column;
      height: 192px;
    }
  }
}

</style>
