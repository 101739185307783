<template>
  <div id="scroll-to" class="mission up-1" v-scrollanimate>
    <h2 class="h4 t-1">The urban transportation challenges of today can only be resolved by rethinking the basics of road infrastructure</h2>
    <div class="p-1">The global growth of cities presents increasing transportation challenges: more cars fill up the roads, more emissions pollute the air, and more precious time is wasted in traffic jams and parking search. The typical response is to keep building the same obsolete highway systems, interchanges and car parks, yet the problem remains. And although the promise of smart devices may help flawed infrastructures a little, even they can’t solve the core of the problem.<br><br><span class="bold">The solution isn’t just <span class="underline">more</span> infrastructure.  At RoadHub, we altogether <span class="underline">rethink</span> infrastructure.</span></div>
  </div>
</template>

<script>
export default {
  name: 'HomeMission'
}
</script>

<style lang="scss" scoped>
.mission {
  @include grid;
  margin-top: 128px;
  margin-bottom: 128px;
  @include laptop {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  @include tablet {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .t-1 {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    margin-top: 54px;
    @include laptop {
      margin-top: 32px;
    }
    @include tablet {
      grid-column: 2 / 8;
    }
    @include phone {
      margin-top: 26px;
      grid-column: 2 / 6;
    }
  }
}

</style>
