<template>
  <div class="grid">
    <div class="viewport">
      <iframe class="iframe" src="https://player.vimeo.com/video/663311350?h=5125427a69&autoplay=1&loop=1&background=1" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen"></iframe>
    </div>
    <!-- <div class="video-overlay-shadow-menu"></div> -->
    <div class="video-overlay-shadow"></div>
    <div class="video-overlay">
      <h1 class="h2 white">Accelerating urban commute with innovative infrastructure design</h1>
    </div>
    <div class="more noselect" @click="scrollTo">
      <div class="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33"><title>Asset 2@gridO</title><circle cx="16.5" cy="16.5" r="16" style="fill:none;stroke:#F0F0F0;stroke-miterlimit:10"/><polyline points="22.5 13.5 16.5 19.5 10.5 13.5" style="fill:none;stroke:#F0F0F0;stroke-miterlimit:10"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeVideo',
  methods: {
    scrollTo () {
      const id = 'scroll-to'
      const yOffset = -120
      const element = document.getElementById(id)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  @include grid;
  .viewport {
    grid-column: 1 / 15;
    grid-row: 1 / 4;
    height: 100vh;
    position: relative;
    z-index: -2;
    pointer-events: none;
    overflow: hidden;
    background-color: $c-a;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    iframe {
      width: 100vw;
      height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
/*   .video-overlay-shadow-menu {
    grid-column: 1 / 15;
    grid-row: 1 / 3;
    height: 100px;
    background-color: $c-a;
    box-shadow: 0 0 30px 30px $c-a;
    opacity: 10%;
    z-index: -1;
  } */
  .video-overlay-shadow {
    grid-column: 1 / 15;
    grid-row: 1 / 4;
    /* grid-row: 2 / 3; */
    background-color: $c-a;
    /* box-shadow: 0 0 100px 100px $c-a; */
    opacity: 0.35;
    z-index: 1;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
  }
  .video-overlay {
    grid-column: 2 / 8;
    grid-row: 2 / 3;
    z-index: 5;
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .more {
    grid-column: 2 / 14;
    grid-row: 3 / 4;
    margin-bottom: 48px;
    margin-top: 32px;
    justify-self: center;
    align-items: center;
    -webkit-align-items: center;
    max-width: 32px;
    z-index: 2;
    cursor: pointer;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-bottom: 82px;
    }
    &:hover {
      opacity: 0.5;
    }
    .svg-container {
      height: 32px;
      svg {
      height: 32px;
      }
    }
  }
}

</style>
