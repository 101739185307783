<template>
  <div class="content-2 up-1" v-scrollanimate>
    <router-link class="image-2" to="/solution#cprs">
      <picture>
        <source srcset="~@/assets/cprs_preview.webp" type="image/webp">
        <source srcset="~@/assets/cprs_preview.jpg" type="image/jpeg">
        <img src="~@/assets/cprs_preview.jpg" alt="CPRS cover" />
      </picture>
    </router-link>
    <h5 class="h3 t-1">CPRS — Saving land with an all-in-one transportation and services complex</h5>
    <div class="p-2">Roadhub's Complete Park & Ride System (CPRS) reinvents the public transportation complex. Instead of building a transportation hub in one place and a Park & Rides in another, CPRS puts them both in a single location, easily accessible to all: in the middle of a highway interchange.<br><br>This condensed geometry also enables commercial and logistical real-estate to be added to the complex. Therefore, lost land has just been transformed into strategic land, an asset that solves multiple infrastructure challenges in one go.</div>
    <router-link class="link" to="/solution#cprs">
      <button class="btn">Read more</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HomeContent2'
}
</script>

<style lang="scss" scoped>
.content-2 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  .image-2 {
    grid-column: 1 / 15;
    grid-row: 1 / 3;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 6;
    grid-row: 3 / 4;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      margin-top: 64px;
    }
  }
  .p-2 {
    margin-top: 128px;
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 4 / 5;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .link {
    grid-column: 8 / 13;
    grid-row: 4 / 5;
    margin-top: 64px;
    @include tablet {
      grid-column: 2 / 7;
      grid-row: 5 / 6;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    button {
      color: $c-a;
      background: none;
      border: 1px solid $c-a;
      border-radius: 28px;
      width: 276px;
      height: 56px;
      &:hover {
        color: $c-w;
        background: $c-e;
        border: 1px solid $c-e;
      }
      @include laptop {
        width: 184px;
        height: 48px;
      }
      @include tablet {
        width: 168px;
        height: 48px;
      }
    }
  }

}

</style>
